import { Link } from 'gatsby'
import React from 'react'
import classnames from 'classnames'

const Links = ({ navIsOpen }) => (
  <nav className={classnames('nav', {'is-open': navIsOpen})}>
    <ul className="nav-list">
      <li className="nav-item">
        <Link
          activeClassName="nav-item__active"
          to="/"
          state={{navIsOpen}}
        >
          Welcome
        </Link>
      </li>
      <li className="nav-item">
          <Link
              activeClassName="nav-item__active"
              to="/video/"
              state={{navIsOpen}}
          >
              Video
          </Link>
      </li>
      <li className="nav-item">
        <Link
          activeClassName="nav-item__active"
          to="/pictures/"
          state={{navIsOpen}}
        >
          Wedding Day
        </Link>
      </li>
      <li className="nav-item">
          <Link
              activeClassName="nav-item__active"
              to="/wedding/"
              state={{navIsOpen}}
          >
              The Wedding
          </Link>
      </li>
      <li className="nav-item">
        <Link
          activeClassName="nav-item__active"
          to="/story/"
          state={{navIsOpen}}
        >
            Our Story
        </Link>
      </li>
      <li className="nav-item">
        <Link
          activeClassName="nav-item__active"
          to="/crete/"
          state={{navIsOpen}}
        >
            Crete
        </Link>
      </li>
    </ul>
  </nav>
)

export default Links
