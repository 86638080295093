import { useStaticQuery, graphql } from 'gatsby'

export default (key) => {
  const meta = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `)

  return { ...meta.site.siteMetadata }
}
