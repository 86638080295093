import React, { useState, useEffect } from 'react'
import { Location, navigate } from '@reach/router'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Links from './links'

import '../styles.css'

const Layout = ({ location, className, children }) => {
  const navIsOpen = location.state && location.state.navIsOpen
  const [blur, setBlur] = useState(true)
  const [showNav, setShowNav] = useState(navIsOpen || false)
  const mainClassNames = classnames('main', className, {
    'blur': !!blur,
    'disabled': showNav,
  })

  const toggleMenu = () => {
    setShowNav(!showNav)
    navigate(undefined, { state: { showMobileNav: !showNav }})
  }

  const closeNav = () => {
    setShowNav(false)
    navigate(undefined, { state: { showMobileNav: false }})
  }

  useEffect(() => {
    setTimeout(() => setBlur(false), 100)

    if (navIsOpen) setTimeout(closeNav, 100)

    window.addEventListener('resize', closeNav)
    return () => {
      window.removeEventListener('resize', closeNav)
    }
  }, [showNav, navIsOpen])

  return (
    <div className="container">
      <button
        className={classnames('burger-nav', {'is-open': showNav})}
        onClick={toggleMenu}
      />
      <Links navIsOpen={showNav} />
      <main className={mainClassNames}>{children}</main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const LocationLayout = ({ children, ...props }) => (
  <Location>
  {({ location }) => (
    <Layout {...props} location={location}>
      {children}
    </Layout>
  )}
  </Location>
)

export default LocationLayout
